<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <b-img
                    :src="logo"
                    class="brand-logo-image"
                    alt="soclib logo"
                    width="120"
                />
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="
                        w-100
                        d-lg-flex
                        align-items-center
                        justify-content-center
                        px-5
                    "
                >
                    <b-img fluid :src="imgUrl" alt="Forgot password V2" />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Forgot password-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-alert
                        class="mb-2"
                        v-height-fade.appear
                        :show="messageShow"
                        :variant="messagesType"
                    >
                        <div
                            class="alert-body"
                            v-if="typeof messages !== 'string'"
                        >
                            <small
                                class="d-block"
                                v-for="(message, index) in messages"
                                :key="index"
                            >
                                {{ message }}
                            </small>
                        </div>
                        <div v-else class="alert-body">
                            <small class="d-block">
                                {{ messages }}
                            </small>
                        </div>
                    </b-alert>
                    <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
                        Forgot Password
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Enter your email and we'll send you instructions to
                        reset your password
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="simpleRules">
                        <b-form
                            class="auth-forgot-password-form mt-2"
                            @submit.prevent="validationForm"
                        >
                            <b-form-group
                                label="Email"
                                label-for="forgot-password-email"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        autocomplete="off"
                                        id="forgot-password-email"
                                        v-model="userEmail"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="forgot-password-email"
                                        placeholder="john@example.com"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-button
                                variant="primary"
                                block
                                type="submit"
                                :disabled="isInProcess"
                            >
                                <b-spinner v-if="isInProcess" small />
                                Send reset link
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <b-link :to="{ name: 'auth-login' }">
                            <feather-icon icon="ChevronLeftIcon" /> Back to
                            login
                        </b-link>
                    </p>
                </b-col>
            </b-col>
            <!-- /Forgot password-->
        </b-row>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BLink,
    BCardTitle,
    BCardText,
    BImg,
    BForm,
    BAlert,
    BFormGroup,
    BSpinner,
    BFormInput,
    BButton,
} from "bootstrap-vue";
import _ from "lodash";
import store from "@/store/index";
import { required, email } from "@validations";
import { heightFade } from "@core/directives/animations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BImg,
        BForm,
        BAlert,
        BButton,
        BFormGroup,
        BSpinner,
        BFormInput,
        BCardTitle,
        BCardText,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        "height-fade": heightFade,
    },
    data() {
        return {
            userEmail: "",
            sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
            // validation
            required,
            email,
            // button loading animation flag
            isInProcess: false,
            // messages properties
            messages: [],
            messageShow: false,
            messagesType: "danger",
        };
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === "dark") {
                this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
                return this.sideImg;
            }
            return this.sideImg;
        },
        logo() {
                return require("@/assets/images/logo/logo_158.png");
        },
    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                    this.isInProcess = true;

                    const params = {
                        email: this.userEmail,
                    };
                    this.$http
                        .post(
                            `${process.env.VUE_APP_URL}/password/forget`,
                            params
                        )
                        .then((response) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: "top-right",
                                props: {
                                    title: "Forgot Password",
                                    icon: "CheckIcon",
                                    variant: "success",
                                    text: response.data,
                                },
                            });

                            localStorage.setItem("fp_email", this.userEmail);
                        })
                        .catch((error) => {
                            this.messageShow = true;
                            this.messagesType = "danger";
                            this.messages = ["Something Went Wrong."];

                            if (
                                error["response"] !== undefined &&
                                error.response["status"] !== undefined
                            ) {
                                switch (error.response.status) {
                                    case 404:
                                        this.messages = ["User Not Found!"];
                                        break;
                                    case 422:
                                        if (
                                            typeof error.response.data ==
                                            "string"
                                        ) {
                                            this.messages = error.response.data;
                                        } else {
                                            this.messages = _.flatten(
                                                Object.values(
                                                    error.response.data
                                                )
                                            );
                                        }
                                        break;
                                    default:
                                        this.messages = [
                                            "Something Went Wrong, Please Try Later.",
                                        ];
                                        break;
                                }
                            }
                        })
                        .finally(() => {
                            this.isInProcess = false;
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
